import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PreviousTicketDataComponent } from './previous-ticket-data/previous-ticket-data.component';
import { ClientContractDataComponent } from './client-contract-data/client-contract-data.component';
import { CalendarComponent } from './calendar/calendar.component';
import { TicketsComponent } from '../../admin/service/tickets/tickets.component';
// import { FlatpickrModule } from 'angularx-flatpickr';
var sharedModules = [
    PreviousTicketDataComponent,
    ClientContractDataComponent,
    CalendarComponent,
    TicketsComponent
];
var ɵ0 = adapterFactory;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };

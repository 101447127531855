import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// Services
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { TicketsService } from '../../../services/tickets.service';
import { ticketsTableOptions } from '../../../constants/tickets';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})

export class TicketsComponent implements OnDestroy, OnInit {
  @Input('type') type?: string;
  broadcast$: Subscription;
  subscriptions: Array<Subscription> = [];
  notSupportedEvents = environment.notSupportedEvents;
  dataTableConfig: any;
  ticketTableOptions = ticketsTableOptions;
  private readonly baseDataTableConfig = {
    config: {
      type: 'tickets',
      base: this.tickets,
      api: 'getTickets',
      role: 3,
      order: [[0, 'desc']]
    },
    columns: [
      {
        display: 'Estatus',
        field: 'id_tickets',
        type: 'ticket_status'
      },
      {
        display: 'Técnico',
        field: 'assignations',
        type: 'ticket_technician'
      },
      {
        display: 'CLIENTE',
        field: 'client.name',
        type: 'ticket_client',
        orderable: false
      },
      {
        display: 'CONTACTO',
        field: 'client',
        type: 'ticket_contact',
        orderable: false
      },
      {
        display: 'FECHA DE CREACION',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: this.ticketTableOptions
      }
    ],
    filters: [
      {
        cssClass: 'col-md-12',
        type: 'status',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 'opened',
            name: 'ABIERTO'
          },
          {
            val: 'assigned',
            name: 'ASIGNADO'
          },
          {
            val: 'completed',
            name: 'COMPLETADO POR EL TECNICO'
          },
          {
            val: 'confirmed',
            name: 'FIRMADO POR EL CLIENTE'
          },
          {
            val: 'closed',
            name: 'CERRADO'
          },
          {
            val: 'unrealized',
            name: 'NO REALIZADO'
          }
        ]
      }
    ]
  };

  constructor(
    private readonly tickets: TicketsService,
    private readonly broadcast: BroadcastService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.setView();
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        if (this.tickets[event.name]) {
          this.tickets[event.name](event.data);
        }
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * setView
   * Configura baseDataTableConfig dependiendo del input ingreasado
   */
  private setView(): void {
    if (this.type && this.type === 'opened') {
      const params = { params: { status: 'opened' } };
      Object.assign(this.baseDataTableConfig.config, params);
      this.baseDataTableConfig.filters = [];
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs';
import { SwalService } from './swal.service';
import { BroadcastService } from './broadcast.service';
import { ModalComponent } from '../components/modal/modal.component';
import { SharedComponent } from '../model/shared-component';
import { AssignComponent } from '../admin/service/tickets/assign/assign.component';
import { AssignationCreateEditComponent } from '../admin/service/tickets/assignation-create-edit/assignation-create-edit.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnrealizedComponent } from '../admin/service/tickets/unrealized/unrealized.component';
import { CreateSupportTicketComponent } from '../admin/clients/create-support-ticket/create-support-ticket.component';
import { CloseComponent } from '../admin/service/tickets/close/close.component';
import { UploadPhotosComponent } from '../components/shared/upload-photos/upload-photos.component';
import { RecordComponent } from '../admin/service/tickets/record/record.component';
import * as i0 from "@angular/core";
import * as i1 from "../components/modal/modal.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "./broadcast.service";
import * as i4 from "./swal.service";
import * as i5 from "@angular/common/http";
var TicketsService = /** @class */ (function () {
    function TicketsService(appModal, activeModal, broadcast, swalService, http) {
        this.appModal = appModal;
        this.activeModal = activeModal;
        this.broadcast = broadcast;
        this.swalService = swalService;
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    TicketsService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/tickets", params);
    };
    TicketsService.prototype.update = function (id_tickets, params) {
        return this.http.put(this.baseUrl + "/ticket/" + id_tickets, params);
    };
    TicketsService.prototype.getTickets = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/tickets/get_tickets", params);
    };
    TicketsService.prototype.getTechnicians = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/users/Technician", params);
    };
    TicketsService.prototype.getErrorCodes = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.get(this.baseUrl + "/error-codes/get_error_codes", params);
    };
    TicketsService.prototype.getHistory = function (id_tickets) {
        var params = {
            params: { id_tickets: id_tickets }
        };
        var assignations = this.http.get(this.baseUrl + "/assignations", params);
        var completions = this.http.get(this.baseUrl + "/completions", params);
        var confirmations = this.http.get(this.baseUrl + "/confirmations", params);
        var unrealizations = this.http.get(this.baseUrl + "/unrealizations", params);
        return forkJoin([assignations, completions, confirmations, unrealizations]);
    };
    TicketsService.prototype.getTicketTypes = function () {
        return this.http.get(this.baseUrl + "/tickets/get_ticket_type");
    };
    TicketsService.prototype.getMapTickets = function (params) {
        return this.http.post(this.baseUrl + "/tickets/get_map_tickets", params);
    };
    TicketsService.prototype.setStatus = function (params, id) {
        return this.http.post(this.baseUrl + "/tickets/" + id + "/setStatus", params);
    };
    TicketsService.prototype.getPhotos = function (ticketId) {
        return this.http.get(this.baseUrl + "/tickets/" + ticketId + "/photos");
    };
    TicketsService.prototype.addPhotos = function (params, ticketId) {
        return this.http.post(this.baseUrl + "/tickets/" + ticketId + "/addPhotos", params);
    };
    TicketsService.prototype.simTicketsNextMonth = function (params) {
        return this.http.post(this.baseUrl + "/tickets/simTicketsNextMonth", params);
    };
    TicketsService.prototype.getInstallationsImages = function (id_clients) {
        return this.http.get(this.baseUrl + "/tickets/" + id_clients + "/getInstallationsImages");
    };
    /***** SharedModals *****/
    /**
     * editTicketModal
     * Abre un modal para editar la fecha - hora de realizacion de un ticket o la
     * descripción sin necesidad de reasignar el ticket
     * @param data informacion del ticket
     */
    TicketsService.prototype.editTicketModal = function (data) {
        var props = new SharedComponent(AssignationCreateEditComponent, { ticket: data, status: 'edit' }, { title: "Edici\u00F3n del ticket #" + data.id_tickets });
        this.appModal.opensm(props);
    };
    /**
     * assignTicketModal
     * Abre un modal para assignar un ticket a un tecnico
     * @param data
     */
    TicketsService.prototype.assignTicketModal = function (data) {
        var props = new SharedComponent(AssignComponent, { ticket: data, status: 'assign' }, { title: "Asignaci\u00F3n de ticket " + data.id_tickets });
        this.appModal.openXl(props);
    };
    /**
     * reassignTicket Modal
     * Abre un modal para reasignar el ticket a otro tecnico
     * @param data
     */
    TicketsService.prototype.reassingTicketModal = function (data) {
        var props = new SharedComponent(AssignComponent, { ticket: data, status: 'reassing' }, { title: "Reasignaci\u00F3n de ticket #" + data.id_tickets });
        this.appModal.openXl(props);
    };
    /**
     * unassignTicketModal
     * Abre un modal para marcar como desaasignado un ticket
     * @param data informacion del ticket
     */
    TicketsService.prototype.unassignTicketModal = function (data) {
        var _this = this;
        var swalParams = {
            title: '¿Desea desasignar este ticket?',
            text: '¡Si desasigna este ticket el estatus pasara a abierto!'
        };
        this.swalService.warning(swalParams).then(function (result) {
            if (result.value) {
                var params = {
                    status: 'unassigned'
                };
                _this.setStatus(params, data.id_tickets).subscribe(function (res) {
                    if (res.success) {
                        _this.swalService.success().then(function () {
                            _this.activeModal.dismiss();
                            _this.broadcast.reloadDataTable();
                        });
                    }
                    else {
                        _this.swalService.error({ title: 'Ocurrió un error al actualizar los datos' });
                    }
                });
            }
        });
    };
    /**
     * closeTicketModal
     * Abre un modal para ingresar los datos de cerrado del ticket por parte del staff
     * @param data informacion del ticket
     */
    TicketsService.prototype.closeTicketModal = function (data) {
        var props = new SharedComponent(CloseComponent, data, { title: "Cerrar el ticket " + data.id_tickets + " del cliente " + data.client.name });
        this.appModal.opensm(props);
    };
    /**
      * unrealizedTicketModal
      * Manda una peticion a la API para marcar un ticket como no realizado.
      * @param data  información del ticket
      */
    TicketsService.prototype.unrealizedTicketModal = function (data) {
        var props = new SharedComponent(UnrealizedComponent, data, { title: "Marcar como no realizado el ticket " + data.id_tickets + " del cliente " + data.client.name });
        this.appModal.opensm(props);
    };
    /**
      * uploadPhotosTicketModal
      * Abre un modal para subir imagenes que se asociaran al ticket
      * @param data
      */
    TicketsService.prototype.uploadPhotosTicketModal = function (data) {
        var props = new SharedComponent(UploadPhotosComponent, data, { title: "Subir imagenes al ticket " + data.id_tickets + " del cliente " + data.client.name });
        this.appModal.opensm(props);
    };
    /**
     * recordModal
     * Abre un modal para visualizar el historial de un ticket
     * @param data informacion del ticket
     */
    TicketsService.prototype.recordModal = function (data) {
        var props = new SharedComponent(RecordComponent, data, { title: "Historial del ticket " + data.id_tickets + " del cliente " + data.client.name });
        this.appModal.openXl(props);
    };
    /**
     * createSupportTicketModal
     * Abre un modal para crear un ticket de soporte
     * @param data informacion del ticket
     */
    TicketsService.prototype.createSupportTicketModal = function (data) {
        var title = 'Crear ticket de soporte';
        if (data && data.name) {
            title = title + "&nbsppara el cliente&nbsp" + data.name;
        }
        var props = new SharedComponent(CreateSupportTicketComponent, data, { title: title });
        this.appModal.open(props);
    };
    TicketsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketsService_Factory() { return new TicketsService(i0.ɵɵinject(i1.ModalComponent), i0.ɵɵinject(i2.NgbActiveModal), i0.ɵɵinject(i3.BroadcastService), i0.ɵɵinject(i4.SwalService), i0.ɵɵinject(i5.HttpClient)); }, token: TicketsService, providedIn: "root" });
    return TicketsService;
}());
export { TicketsService };

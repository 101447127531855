import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CalendarService {
    baseUrl = `${environment.apiUrl}`;
    constructor(private readonly http: HttpClient) { }

    /**
     * getAssignedTickets
     * Obtiene la lista de los tickets con el estatus assignado
     * con el formato necesario para el calendario
     * @param params 
     * @returns 200 | 404 | 500
     */
    getAssignedTickets(params?): Observable<any> {
        return this.http.post(`${this.baseUrl}/calendar/getAssignedTickets`, params);
    }
}

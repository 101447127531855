import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-poll-ranking',
  templateUrl: './poll-ranking.component.html',
  styleUrls: ['./poll-ranking.component.scss']
})
export class PollRankingComponent {
  @Input() rateSelected = 1;
}

import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { TicketsService } from '../../../services/tickets.service';
import { CalendarService } from '../../../services/calendar.service';
import { ticketsTableOptions } from '../../../constants/tickets';
import { BroadcastService } from '../../../services/broadcast.service';
import { environment } from '../../../../environments/environment';
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(modal, broadcast, cdRef, calendarService, ticketsService) {
        this.modal = modal;
        this.broadcast = broadcast;
        this.cdRef = cdRef;
        this.calendarService = calendarService;
        this.ticketsService = ticketsService;
        this.ticketTableOptions = ticketsTableOptions;
        this.notSupportedEvents = environment.notSupportedEvents;
        this.subscriptions = [];
        this.view = CalendarView.Month;
        this.CalendarView = CalendarView;
        this.viewDate = new Date();
        this.refresh = new Subject();
        this.events = [];
        this.activeDayIsOpen = true;
    }
    CalendarComponent.prototype.ngOnInit = function () {
        this.getAssignedTickets();
    };
    CalendarComponent.prototype.ngAfterViewChecked = function () {
        this.cdRef.detectChanges();
    };
    CalendarComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    CalendarComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        this.viewDate = date;
    };
    CalendarComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        this.events = this.events.map(function (iEvent) {
            if (iEvent === event) {
                return tslib_1.__assign({}, event, { start: newStart, end: newEnd });
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    };
    CalendarComponent.prototype.handleEvent = function (action, event) {
        this.modalData = { event: event, action: action };
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    CalendarComponent.prototype.deleteEvent = function (eventToDelete) {
        this.events = this.events.filter(function (event) { return event !== eventToDelete; });
    };
    CalendarComponent.prototype.setView = function (view) {
        this.view = view;
    };
    CalendarComponent.prototype.closeOpenMonthViewDay = function () {
        this.activeDayIsOpen = false;
    };
    /**
     * getAssignedTickets
     * Obtiene los tickets con status asignado y añade las acciones para los eventos
     * asignar, desaasignar, marcar como no realizado
     */
    CalendarComponent.prototype.getAssignedTickets = function () {
        var _this = this;
        this.subscriptions.push(this.calendarService.getAssignedTickets().subscribe(function (response) {
            if (response.response) {
                _this.events = response.response;
                _this.events.forEach(function (ticketEvent) {
                    var _a;
                    ticketEvent.start = new Date(ticketEvent.start);
                    ticketEvent.end = new Date(ticketEvent.end);
                    var actions = _this.addTicketActions();
                    (_a = ticketEvent.actions).push.apply(_a, actions);
                });
            }
        }));
    };
    /**
     * addTicketsActions
     * Añade las acciones disponibles para el evento de tickets
     * @returns Array CalendarEventAction
     */
    CalendarComponent.prototype.addTicketActions = function () {
        var _this = this;
        var actions = [
            {
                label: this.setLabel('Editar'),
                onClick: function (_a) {
                    var event = _a.event;
                    _this.ticketsService.editTicketModal(event.data);
                }
            },
            {
                label: this.setLabel('Desassignar'),
                onClick: function (_a) {
                    var event = _a.event;
                    _this.ticketsService.unassignTicketModal(event.data);
                }
            },
            {
                label: this.setLabel('Asignar'),
                onClick: function (_a) {
                    var event = _a.event;
                    _this.ticketsService.reassingTicketModal(event.data);
                }
            },
            {
                label: this.setLabel('Reasignar'),
                onClick: function (_a) {
                    var event = _a.event;
                    _this.ticketsService.reassingTicketModal(event.data);
                }
            }
        ];
        return actions;
    };
    /**
     * setLabel
     * Configura la etiqueta de acción mostrada para los eventos
     * @param label: string Texto de la etiqueta
     * @param icon : string que hace referencia a icono font awsome
     * @returns string
     */
    CalendarComponent.prototype.setLabel = function (label, icon) {
        var formatedLabel = label;
        if (icon) {
            formatedLabel = "<i class=\"" + icon + "\"></i>&nbsp" + formatedLabel;
        }
        formatedLabel = formatedLabel + "&nbsp";
        return formatedLabel;
    };
    return CalendarComponent;
}());
export { CalendarComponent };

<div class="container" *ngIf="results">
    <h3>Total de encuestados: {{ results.totalRespondents }}</h3>
    <div class="multiple" *ngFor="let result of results.questions?.multiple">
      <h4>{{ result.question.description }}</h4>
      <table class="table table-sm table-striped">
        <tbody>
          <tr *ngFor="let data of result.question.results">
            <td>{{ data.option }}</td>
            <td>{{ data.evaluation }}</td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>

  <div class="ranking" *ngFor="let result of results.questions?.ranking">
    <h4>{{ result.question.description }}</h4>
    <table class="table table-sm table-striped">
        <tbody>
          <tr *ngFor="let rate of [5,4,3,2,1]">
            <td><app-poll-ranking [rateSelected]="rate"></app-poll-ranking></td>
            <td>{{ result.question.results[rate] ? result.question.results[rate] : 0}}</td>
          </tr>
        </tbody>
      </table>
      <br />
  </div>

  <div class="single" *ngFor="let result of results.questions?.single">
    <h4>{{ result.question.description }}</h4>
    <table class="table table-sm table-striped">
      <tbody>
        <tr *ngFor="let data of result.question.results">
          <td>{{ data.answer }}</td>
        </tr>
      </tbody>
    </table>
    <pagination
      [totalItems]=result.question.totalItems
      [itemsPerPage]=result.question.perPage
      (pageChanged)="pageChanged(result.question.id, $event)"
    ></pagination>
    <br />
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tickets.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../components/datatable/datatable.component.ngfactory";
import * as i4 from "../../../components/datatable/datatable.component";
import * as i5 from "../../../services/broadcast.service";
import * as i6 from "./tickets.component";
import * as i7 from "../../../services/tickets.service";
import * as i8 from "../../../services/permissions.service";
var styles_TicketsComponent = [i0.styles];
var RenderType_TicketsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketsComponent, data: {} });
export { RenderType_TicketsComponent as RenderType_TicketsComponent };
function View_TicketsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Seguimiento de Tickets"]))], null, null); }
export function View_TicketsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "app-datatable", [], null, null, null, i3.View_DatatableComponent_0, i3.RenderType_DatatableComponent)), i1.ɵdid(4, 245760, null, 0, i4.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i5.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.dataTableConfig; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TicketsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tickets", [], null, null, null, View_TicketsComponent_0, RenderType_TicketsComponent)), i1.ɵdid(1, 245760, null, 0, i6.TicketsComponent, [i7.TicketsService, i5.BroadcastService, i8.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketsComponentNgFactory = i1.ɵccf("app-tickets", i6.TicketsComponent, View_TicketsComponent_Host_0, { type: "type" }, {}, []);
export { TicketsComponentNgFactory as TicketsComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./poll-ranking.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./poll-ranking.component";
var styles_PollRankingComponent = [i0.styles];
var RenderType_PollRankingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PollRankingComponent, data: {} });
export { RenderType_PollRankingComponent as RenderType_PollRankingComponent };
function View_PollRankingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit <= _co.rateSelected) ? "fa fa-star" : "fa fa-star-o"); _ck(_v, 0, 0, currVal_0); }); }
export function View_PollRankingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_PollRankingComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(2, 5)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, 1, 2, 3, 4, 5); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PollRankingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-poll-ranking", [], null, null, null, View_PollRankingComponent_0, RenderType_PollRankingComponent)), i1.ɵdid(1, 49152, null, 0, i3.PollRankingComponent, [], null, null)], null, null); }
var PollRankingComponentNgFactory = i1.ɵccf("app-poll-ranking", i3.PollRankingComponent, View_PollRankingComponent_Host_0, { rateSelected: "rateSelected" }, {}, []);
export { PollRankingComponentNgFactory as PollRankingComponentNgFactory };

import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../../services/broadcast.service';
import { GroupsService } from '../../../../services/groups.service';
import { PollsService } from '../../../../services/polls.service';
import { SwalService } from '../../../../services/swal.service';
var PollAssignComponent = /** @class */ (function () {
    function PollAssignComponent(activeModal, swalService, broadcast, groupsService, formBuilder, pollsService) {
        this.activeModal = activeModal;
        this.swalService = swalService;
        this.broadcast = broadcast;
        this.groupsService = groupsService;
        this.formBuilder = formBuilder;
        this.pollsService = pollsService;
        this.subscription = new Subscription();
        this.form = this.formBuilder.group({
            clients: [[]],
            groups: [[]]
        });
    }
    PollAssignComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataTableClientConfig = this.setTableConfig({
            base: this.pollsService,
            api: 'getEligibleClients'
        }, 'assign.client');
        this.dataTableGroupConfig = this.setTableConfig({
            base: this.groupsService,
            api: 'getGroups'
        }, 'assign.group');
        this.subscription.add(this.pollsService.assignments(this.data.pollId).subscribe(function (assigments) {
            if (assigments.success) {
                _this.form.patchValue(assigments.response);
            }
        }));
        this.initBroadcast();
    };
    PollAssignComponent.prototype.setTableConfig = function (config, event) {
        return {
            config: config,
            columns: [
                {
                    display: 'Nombre',
                    field: 'name',
                    type: 'text'
                },
                {
                    display: '',
                    field: '',
                    type: 'inline-button',
                    options: [
                        {
                            cssClass: 'btn btn-success',
                            name: 'Seleccionar',
                            event: event,
                            conditionality: 'true'
                        }
                    ]
                }
            ]
        };
    };
    PollAssignComponent.prototype.addItem = function (tableName, data) {
        var _a;
        if (this.form.get(tableName).value.find(function (item) { return item["id_" + tableName] === data["id_" + tableName]; })) {
            var target = tableName === 'clients' ? 'cliente' : 'grupo';
            return this.swalService.error({ title: "Ya est\u00E1 asignada a este " + target + ".", text: '' });
        }
        this.form.get(tableName).setValue(this.form.get(tableName).value.concat([
            (_a = {},
                _a["id_" + tableName] = data["id_" + tableName],
                _a.name = data.name,
                _a)
        ]));
        this.form.markAsDirty();
    };
    PollAssignComponent.prototype.initBroadcast = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'assign.client':
                    _this.addItem('clients', event.data);
                    break;
                case 'assign.group':
                    _this.addItem('groups', event.data);
                    break;
            }
        });
    };
    PollAssignComponent.prototype.removeItem = function (table, itemId) {
        var actualItems = this.form.get(table).value.filter(function (item) { return item["id_" + table] !== itemId; });
        this.form.get(table).setValue(actualItems);
        this.form.markAsDirty();
    };
    PollAssignComponent.prototype.removeClient = function (clientId) {
        this.removeItem('clients', clientId);
    };
    PollAssignComponent.prototype.removeGroup = function (groupId) {
        this.removeItem('groups', groupId);
    };
    PollAssignComponent.prototype.save = function () {
        var _this = this;
        var service = this.pollsService.assign(this.data.pollId, this.form.value);
        var swalParams = {
            title: 'Asignación de encuesta',
            text: '¿Esta seguro de asignar esta encuesta a los siguientes clientes y/o grupos?'
        };
        if (this.form.valid) {
            this.swalService.warning(swalParams).then(function (result) {
                if (result.value) {
                    _this.subscription.add(service.subscribe(function (resp) {
                        if (resp.success) {
                            _this.swalService.success().then(function () {
                                _this.activeModal.dismiss();
                            });
                        }
                        else {
                            _this.swalService.error({ title: 'Ocurió un error al guardar los datos' });
                        }
                    }));
                }
            });
        }
    };
    PollAssignComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
        this.subscription.unsubscribe();
    };
    return PollAssignComponent;
}());
export { PollAssignComponent };

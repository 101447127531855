import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  isSameMonth,
} from 'date-fns';
import { colors } from '../../../constants/colors';
import { Subject, Subscription } from 'rxjs';
import { TicketsService } from '../../../services/tickets.service';
import { CalendarService } from '../../../services/calendar.service';
import { ticketsTableOptions } from '../../../constants/tickets';
import { BroadcastService } from '../../../services/broadcast.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnDestroy, OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  dataTableConfig: any;
  ticketTableOptions = ticketsTableOptions;
  notSupportedEvents = environment.notSupportedEvents;
  subscriptions: Array<Subscription> = [];
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  constructor(
    private readonly modal: NgbModal,
    private readonly broadcast: BroadcastService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly calendarService: CalendarService,
    public readonly ticketsService: TicketsService,
  ) { }

  ngOnInit(): void {
    this.getAssignedTickets();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.viewDate = date;
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  /**
   * getAssignedTickets
   * Obtiene los tickets con status asignado y añade las acciones para los eventos
   * asignar, desaasignar, marcar como no realizado
   */
  private getAssignedTickets(): void {
    this.subscriptions.push(
      this.calendarService.getAssignedTickets().subscribe(response => {
        if (response.response) {
          this.events = response.response;
          this.events.forEach((ticketEvent) => {
            ticketEvent.start = new Date(ticketEvent.start);
            ticketEvent.end = new Date(ticketEvent.end);
            const actions = this.addTicketActions();
            ticketEvent.actions.push(...actions);
          });
        }
      })
    );
  }

  /**
   * addTicketsActions
   * Añade las acciones disponibles para el evento de tickets
   * @returns Array CalendarEventAction
   */
  private addTicketActions(): Array<CalendarEventAction> {
    const actions = [
      {
        label: this.setLabel('Editar'),
        onClick: ({ event }: { event }): void => {
          this.ticketsService.editTicketModal(event.data);
        }
      },
      {
        label: this.setLabel('Desassignar'),
        onClick: ({ event }: { event }): void => {
          this.ticketsService.unassignTicketModal(event.data);
        }
      },
      {
        label: this.setLabel('Asignar'),
        onClick: ({ event }: { event }): void => {
          this.ticketsService.reassingTicketModal(event.data);
        }
      },
      {
        label: this.setLabel('Reasignar'),
        onClick: ({ event }: { event }): void => {
          this.ticketsService.reassingTicketModal(event.data);
        }
      }
    ];

    return actions;
  }

  /**
   * setLabel
   * Configura la etiqueta de acción mostrada para los eventos
   * @param label: string Texto de la etiqueta
   * @param icon : string que hace referencia a icono font awsome
   * @returns string
   */
  private setLabel(label: string, icon?: string): string {
    let formatedLabel = label;
    if (icon) {
      formatedLabel = `<i class="${icon}"></i>&nbsp${formatedLabel}`;
    }

    formatedLabel = `${formatedLabel}&nbsp`;
    return formatedLabel;
  }
}

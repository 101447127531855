// Angular
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
// 3rd Party Modules
import {
  AppAsideModule,
  AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule
} from '@coreui/angular';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import * as moment from 'moment';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxLoadingModule } from 'ngx-loading';
import {
  PerfectScrollbarConfigInterface, PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { routes } from './app.routing';
import { CustomAdapter, CustomDateParserFormatter } from './components/shared/datepickerformater';
// Components
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
// Interceptors
import { CatchAllInterceptorService } from './providers/catch-all-intercerptor.service';
import { RefreshTokenInterceptorService } from './providers/refresh-token-interceptor.service';
import { AuthorizationInterceptorService } from './providers/authorization-interceptor.service';
// App own Modules
import { SessionModule } from './session/session.module';
import { NgbTimeStringAdapter } from './components/shared/timepickerformater';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// registerLocaleData(localeEs, 'es-MX');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const APP_CONTAINERS = [
  DefaultLayoutComponent,
  AppComponent
];

@NgModule({
  imports: [
    AdminModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    NgbModule,
    NgxLoadingModule.forRoot({
      animationType: 'sk-circle',
      primaryColour: '#20a8d8',
      secondaryColour: '#dddddd',
      backdropBackgroundColour: 'rgba(255, 255, 255, 0.5)',
      backdropBorderRadius: '5px'
    }),
    PerfectScrollbarModule,
    RouterModule.forRoot(routes),
    SessionModule,
    TabsModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: APP_CONTAINERS,
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CatchAllInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptorService,
      multi: true
    },
    // {
    //   provide: LOCALE_ID, useValue: 'es-MX'
    // },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    moment.defineLocale('es-mx', {
      parentLocale: 'es',
      months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
      monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic.'.split('_'),
      monthsParseExact: true,
      weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
      weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
      weekdaysMin: 'do_lu_ma_mi_ju_vi_sá'.split('_'),
      weekdaysParseExact: true,
      dayOfMonthOrdinalParse: /\d{1,2}/,
      ordinal(dayNumber: any): any {
        return dayNumber;
      }
    });
    moment.locale('es-mx');
  }
}

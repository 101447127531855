/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./poll-results.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../poll-ranking/poll-ranking.component.ngfactory";
import * as i4 from "../poll-ranking/poll-ranking.component";
import * as i5 from "../../../../../../node_modules/ngx-bootstrap/pagination/ngx-bootstrap-pagination.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "ngx-bootstrap/pagination";
import * as i8 from "./poll-results.component";
import * as i9 from "../../../../services/polls.service";
var styles_PollResultsComponent = [i0.styles];
var RenderType_PollResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PollResultsComponent, data: {} });
export { RenderType_PollResultsComponent as RenderType_PollResultsComponent };
function View_PollResultsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.option; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.evaluation; _ck(_v, 4, 0, currVal_1); }); }
function View_PollResultsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "multiple"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "table", [["class", "table table-sm table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollResultsComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.question.results; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.question.description; _ck(_v, 2, 0, currVal_0); }); }
function View_PollResultsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-poll-ranking", [], null, null, null, i3.View_PollRankingComponent_0, i3.RenderType_PollRankingComponent)), i1.ɵdid(3, 49152, null, 0, i4.PollRankingComponent, [], { rateSelected: [0, "rateSelected"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.question.results[_v.context.$implicit] ? _v.parent.context.$implicit.question.results[_v.context.$implicit] : 0); _ck(_v, 5, 0, currVal_1); }); }
function View_PollResultsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ranking"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "table", [["class", "table table-sm table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PollResultsComponent_5)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(7, 5), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = _ck(_v, 7, 0, 5, 4, 3, 2, 1); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.question.description; _ck(_v, 2, 0, currVal_0); }); }
function View_PollResultsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.answer; _ck(_v, 2, 0, currVal_0); }); }
function View_PollResultsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "single"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "table", [["class", "table table-sm table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollResultsComponent_7)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "pagination", [], null, [[null, "pageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.pageChanged(_v.context.$implicit.question.id, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_PaginationComponent_0, i5.RenderType_PaginationComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.PaginationComponent]), i1.ɵdid(9, 114688, null, 0, i7.PaginationComponent, [i1.ElementRef, i7.PaginationConfig, i1.ChangeDetectorRef], { itemsPerPage: [0, "itemsPerPage"], totalItems: [1, "totalItems"] }, { pageChanged: "pageChanged" }), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.question.results; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.question.perPage; var currVal_3 = _v.context.$implicit.question.totalItems; _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.question.description; _ck(_v, 2, 0, currVal_0); }); }
function View_PollResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Total de encuestados: ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollResultsComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollResultsComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollResultsComponent_6)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.results.questions == null) ? null : _co.results.questions.multiple); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.results.questions == null) ? null : _co.results.questions.ranking); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.results.questions == null) ? null : _co.results.questions.single); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.results.totalRespondents; _ck(_v, 2, 0, currVal_0); }); }
export function View_PollResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollResultsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.results; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PollResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-poll-results", [], null, null, null, View_PollResultsComponent_0, RenderType_PollResultsComponent)), i1.ɵdid(1, 114688, null, 0, i8.PollResultsComponent, [i9.PollsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PollResultsComponentNgFactory = i1.ɵccf("app-poll-results", i8.PollResultsComponent, View_PollResultsComponent_Host_0, { data: "data" }, {}, []);
export { PollResultsComponentNgFactory as PollResultsComponentNgFactory };

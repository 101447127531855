import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../../components/components.module';
import { ServiceRoutes } from './service.routing';
import { ErrorCodesComponent } from './error-codes/error-codes.component';
import { AgmCoreModule } from '@agm/core';
import { TicketsMapComponent } from './tickets-map/tickets-map.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '../../components/shared/shared.module';

@NgModule({
  declarations: [
    TicketsMapComponent,
    ErrorCodesComponent
  ],
  exports: [
    TicketsMapComponent,
    ErrorCodesComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule.forChild(ServiceRoutes),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBq6jUoU6vrft-0hVUjkcAyeeM4zSuahuo'
      // libraries: ['places']
    }),
    NgxPermissionsModule.forChild(),
    SharedModule
  ]
})
export class ServiceModule { }

<br />
<div class="container">
  <ngb-tabset>
    <ngb-tab title="Asignar a cliente">
      <ng-template ngbTabContent>
        <div class="row ng-scope">
          <div class="col-md-6">
            <div class="panel panel-default">
              <div class="panel-heading">
                <i>Clientes</i>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <tbody>
                      <app-datatable [options]="dataTableClientConfig"></app-datatable>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel panel-default permissions-tab">
              <div class="panel-heading">
                <i>Clientes seleccionados</i>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <tbody>
                      <tr *ngFor="let client of form.controls.clients.value">
                        <td class="col-md-8">{{ client.name }}</td>
                        <td class="col-md-4 remove"><button type="button" class="btn btn-success" (click)="removeClient(client.id_clients)">Remover</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Asignar a grupo">
      <ng-template ngbTabContent>
        <div class="row ng-scope">
          <div class="col-md-6">
            <div class="panel panel-default">
              <div class="panel-heading">
                <i>Groups</i>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <tbody>
                      <app-datatable [options]="dataTableGroupConfig"></app-datatable>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="panel panel-default permissions-tab">
              <div class="panel-heading">
                <i>Grupos seleccionados</i>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <tbody>
                      <tr *ngFor="let group of form.controls.groups.value">
                        <td class="col-md-8">{{ group.name }}</td>
                        <td class="col-md-4 remove"><button type="button" class="btn btn-success" (click)="removeGroup(group.id_groups)">Remover</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
  <br />
  <div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark"
            (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>
</div>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tickets-history',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  @Input('item') item: any;

  ngOnInit(): void {
    //
  }

}

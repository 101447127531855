import { Component, Input, OnInit } from '@angular/core';
import { PollsService } from '../../../../services/polls.service';

@Component({
  selector: 'app-poll-results',
  templateUrl: './poll-results.component.html',
  styleUrls: ['./poll-results.component.scss']
})
export class PollResultsComponent implements OnInit {
  @Input() data: any;
  results;

  constructor(
    private readonly pollsService: PollsService
  ) { }

  ngOnInit() {
    this.pollsService.getResults(this.data.pollId).subscribe(results => {
      if (results && results.success) {
        this.results = results.response;
      }
    });
  }

  pageChanged(questionId, event) {
    this.pollsService.getSingleResults(this.data.pollId, questionId, event.page).subscribe((singleResults: any) => {
      const question = this.results.questions.single.find(singleQuestion => singleQuestion.question.id === questionId);
      question.question.results = singleResults.data.map(result => {
        return { answer: result.answer };
      });
    });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PreviousTicketDataComponent } from './previous-ticket-data/previous-ticket-data.component';
import { ClientContractDataComponent } from './client-contract-data/client-contract-data.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FormsModule } from '@angular/forms';
import { DatatableModule } from '../datatable/datatable.module';
import { TicketsComponent } from '../../admin/service/tickets/tickets.component';
// import { FlatpickrModule } from 'angularx-flatpickr';

const sharedModules = [
  PreviousTicketDataComponent,
  ClientContractDataComponent,
  CalendarComponent,
  TicketsComponent
];

@NgModule({
  declarations: [...sharedModules],
  exports:[...sharedModules],
  entryComponents:[],
  imports: [
    CommonModule,
    FormsModule,
    DatatableModule,
    // FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ]
})
export class SharedModule { }

import * as moment from 'moment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
// Components
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
// registerLocaleData(localeEs, 'es-MX');
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var APP_CONTAINERS = [
    DefaultLayoutComponent,
    AppComponent
];
var ɵ0 = adapterFactory;
var AppModule = /** @class */ (function () {
    function AppModule() {
        moment.defineLocale('es-mx', {
            parentLocale: 'es',
            months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
            monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic.'.split('_'),
            monthsParseExact: true,
            weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
            weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
            weekdaysMin: 'do_lu_ma_mi_ju_vi_sá'.split('_'),
            weekdaysParseExact: true,
            dayOfMonthOrdinalParse: /\d{1,2}/,
            ordinal: function (dayNumber) {
                return dayNumber;
            }
        });
        moment.locale('es-mx');
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };

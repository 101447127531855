<div class="container-fluid">
    <div class="row">
        <div class="col-md-6">
            <app-form-field type="input" [options]="{label: 'Nombre', path: 'name'}"></app-form-field>
        </div>
        <div class="col-md-6">
            <app-form-field type="select" [options]="{
                label: 'Area',
                path: 'area',
                elements: areas
            }">
            </app-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <h3 class="text-center">Añadir pregunta &nbsp;&nbsp;
                <button *ngIf="questions.controls.length < 10" (click)="addQuestion()"
                    class="btn btn-outline-primary btn-sm"><i class="fa fa-plus"></i></button>
            </h3>
            <hr />
        </div>
    </div>

    <div class="row" *ngFor="let question of questions.controls; let qIndex=index">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-7">
                    <app-form-field type="input"
                        [options]="{label: 'Pregunta&nbsp;' + (qIndex + 1),  path: 'questions.' + qIndex + '.description'}">
                    </app-form-field>
                </div>
                <div class="col-md-4">
                    <app-form-field type="select" [options]="{
                    label: 'Tipo',
                    path: 'questions.' + qIndex + '.type',
                    elements: questionsTypes
                }">
                    </app-form-field>
                </div>
                <div class="col-md-1">
                    <button (click)="removeQuestion(qIndex)" class="btn btn-outline-danger btn-sm"><i
                            class="fa fa-trash"></i></button>
                </div>
            </div>
            <div class="row" *ngIf="questions.controls[qIndex]?.controls.type.value === 'multiple'">
                <div class="col-md-12">
                    <h5 class="text-center">Añadir opciones para la pregunta {{qIndex + 1}} &nbsp;&nbsp;
                        <button (click)="addQuestionOption(qIndex)" class="btn btn-outline-primary btn-sm"><i
                                class="fa fa-plus"></i></button>
                    </h5>
                    <div class="row" *ngFor="let option of questionOptions(qIndex).controls; let oIndex=index">
                        <div class="col-md-11">
                            <app-form-field type="input"
                                [options]="{label: 'Opción &nbsp;' + (oIndex + 1),  path: 'questions.' + qIndex + '.question_options.' + oIndex + '.question_option'}">
                            </app-form-field>
                        </div>
                        <div class="col-md-1">
                            <button (click)="removeQuestionOption(qIndex, oIndex)" class="btn btn-outline-danger btn-sm"><i
                                    class="fa fa-trash"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">{{buttonName}}</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>
<div class="container-fluid border-bottom bg-white p-3 mb-4" *ngIf="poll?.questions?.length; else noPolls">
    <ngb-accordion *ngFor="let question of poll.questions">
        <ngb-panel title="{{question.description}} ({{ question.type }})">
            <ng-template ngbPanelContent>
                <ul  *ngIf="question?.question_options?.length">
                    <li *ngFor="let option of question.question_options">{{option.question_option}}</li>
                </ul>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>

<ng-template #noPolls>
    <div class="center">
        <h3>Esta encuesta no tiene preguntas asociadas</h3>
    </div>   
</ng-template>

import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from '../containers/default-layout/default-layout.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { RoleGuardService } from '../services/role-guard.service';
import { ClientsComponent } from './clients/clients.component';
import { AdminDashboardComponent } from './dashboard/admin-dashboard/admin-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvestorDashboardComponent } from './dashboard/investor-dashboard/investor-dashboard.component';
import { SalesDashboardComponent } from './dashboard/sales-dashboard/sales-dashboard.component';
import { CalendarComponent } from '../components/shared/calendar/calendar.component';
var ɵ0 = {
    expectedRoles: [{ role: 'Administrator' }]
};
export var AdminRoutes = [
    {
        path: 'admin',
        redirectTo: 'admin/inicio',
        pathMatch: 'full'
    },
    {
        path: 'admin',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        children: [
            {
                path: 'inicio',
                component: DashboardComponent,
                canActivate: [RoleGuardService]
            },
            {
                path: 'dashboard-ventas',
                component: SalesDashboardComponent,
                canActivate: [RoleGuardService]
            },
            {
                path: 'dashboard-inversores',
                component: InvestorDashboardComponent,
                canActivate: [RoleGuardService]
            },
            {
                path: 'dashboard-admin',
                component: AdminDashboardComponent,
                canActivate: [RoleGuardService]
            },
            {
                path: 'clientes',
                component: ClientsComponent,
                canActivate: [RoleGuardService]
            },
            {
                path: 'calendario',
                component: CalendarComponent,
                canActivate: [RoleGuardService]
            },
            {
                path: 'administracion',
                loadChildren: './administration/administration.module#AdministrationModule',
                canActivate: [RoleGuardService],
                data: ɵ0
            },
            {
                path: 'servicio',
                loadChildren: './service/service.module#ServiceModule',
                canActivate: [RoleGuardService]
            },
            {
                path: 'social',
                loadChildren: './social/social.module#SocialModule',
                canActivate: [RoleGuardService]
            },
            {
                path: 'finanzas',
                loadChildren: './finances/finances.module#FinancesModule',
                canActivate: [RoleGuardService]
            },
            {
                path: 'vendedores',
                loadChildren: './sellers/sellers.module#SellersModule',
                canActivate: [RoleGuardService]
            },
            {
                path: 'bodega',
                loadChildren: './warehouse/warehouse.module#WarehouseModule',
                canActivate: [RoleGuardService]
            },
            {
                path: 'dev',
                loadChildren: './dev/dev.module#DevModule',
                canActivate: [RoleGuardService]
            },
            {
                path: 'iot',
                loadChildren: './iot/iot.module#IoTModule',
                canActivate: [RoleGuardService]
            }
        ]
    }
];
var AdminModule = /** @class */ (function () {
    function AdminModule() {
    }
    return AdminModule;
}());
export { AdminModule };
export { ɵ0 };

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PollsService {
    baseUrl = `${environment.apiUrl}`;

    constructor(
        private readonly http: HttpClient
    ) {
    }

    /**
     * getPolls
     * Obtiene la tabla con los datos de las encuestas almacenadas en la BD.
     * @param params parametros por los cuales se va a obtener la tabla, asi como el orden de los datos
     * @param filters filtros por los cuales se podra obtener datos especificos de la tabla en caso de ser necesario.
     * @returns los valores para dibujar la tabla
     */
    getPolls(params?, filters?): Observable<any> {
        if (filters) {
            Object.assign(params, filters);
        }

        return this.http.post(`${this.baseUrl}/polls/getPolls`, params);
    }

    /**
     * create
     * Envia una petición post para crear una encuesta con la opciones asociadas.
     * @param params name: nombre de la encuesta, area: area a la cual se va a evaluar, questions: array con las preguntas a evaluar
     * @returns 200 status OK | 500 error interno.
     */
    create(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/polls`, params);
    }

    /**
     * delete
     * Envia una petición delete para borrar una encuesta con la opciones asociadas.
     * @param id id: de la encuesta a borrar
     * @returns 200 status OK | 404 status not found
     */
    delete(id): Observable<any> {

        return this.http.delete(`${this.baseUrl}/polls/${id}`);
    }
    /**
     * show
     * Envia una petición get para obtener  la informacion de una encuesta con la opciones asociadas.
     * @param id id: de la encuesta a consultar
     * @returns 200 status OK | 404 status not found
     */
    show(id): Observable<any> {

        return this.http.get(`${this.baseUrl}/polls/${id}`);
    }

    /**
     * update
     * Envia una petición put para actualizar una encuesta con la opciones asociadas.
     * @param params name: nombre de la encuesta, area: area a la cual se va a evaluar, questions: array con las preguntas a evaluar
     * @returns 200 status OK | 500 error interno.
     */
    update(id, params): Observable<any> {

        return this.http.put(`${this.baseUrl}/polls/${id}`, params);
    }

    /**
     * assign
     * Envia una petición put para actualizar una encuesta con la opciones asociadas.
     * @param id ID de la encuesta
     * @param params arrays de clientes y grupos
     * @returns 200 status OK | 500 error interno.
     */
     assign(id, params): Observable<any> {
        return this.http.put(`${this.baseUrl}/polls/${id}/assign`, params);
    }

    /**
     * assignments
     * Envia una petición get para obtener las asignaciones de una encuesta.
     * @param id ID de la encuesta
     * @returns 200 status OK | 500 error interno.
     */
     assignments(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/polls/${id}/assignments`);
    }

    /**
     * results
     * Envia una petición get para obtener los resultados de una encuesta.
     * @param id ID de la encuesta
     * @returns 200 status OK | 500 error interno.
     */
    getResults(id: number): Observable<any> {
        return this.http.get(`${this.baseUrl}/polls/${id}/results`);
    }

    getSingleResults(id: number, questionId: number, page = '1') {
        return this.http.get(`${this.baseUrl}/polls/${id}/singleResults/${questionId}`, { params: { page: page.toString() } });
    }

    getEligibleClients(params?, filters?): Observable<any> {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(`${this.baseUrl}/polls/getEligibleClients`, params);
    }
}

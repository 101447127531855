import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../../services/broadcast.service';
import { GroupsService } from '../../../../services/groups.service';
import { PollsService } from '../../../../services/polls.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-poll-assign',
  templateUrl: './poll-assign.component.html',
  styleUrls: ['./poll-assign.component.scss']
})
export class PollAssignComponent implements OnInit, OnDestroy {
  @Input() data: any;
  
  private subscription = new Subscription();
  form: FormGroup = this.formBuilder.group({
    clients: [[]],
    groups: [[]]
  });

  dataTableClientConfig;
  dataTableGroupConfig;

  broadcast$: Subscription;

  constructor(
    public readonly activeModal: NgbActiveModal,
    private readonly swalService: SwalService,
    private readonly broadcast: BroadcastService,
    private readonly groupsService: GroupsService,
    private readonly formBuilder: FormBuilder,
    private readonly pollsService: PollsService
  ) { }

  ngOnInit() {
    this.dataTableClientConfig = this.setTableConfig({
      base: this.pollsService,
      api: 'getEligibleClients'
    }, 'assign.client');

    this.dataTableGroupConfig = this.setTableConfig({
      base: this.groupsService,
      api: 'getGroups'
    }, 'assign.group');

    this.subscription.add(
      this.pollsService.assignments(this.data.pollId).subscribe(assigments => {
        if (assigments.success) {
          this.form.patchValue(assigments.response);
        }
      })
    );

    this.initBroadcast();
  }

  private setTableConfig(config, event) {
    return {
      config,
      columns: [
        {
          display: 'Nombre',
          field: 'name',
          type: 'text'
        },
        {
          display: '',
          field: '',
          type: 'inline-button',
          options: [
            {
              cssClass: 'btn btn-success',
              name: 'Seleccionar',
              event,
              conditionality: 'true'
            }
          ]
        }
      ]
    };
  }

  private addItem(tableName: string, data) {
    if (this.form.get(tableName).value.find(item => item[`id_${tableName}`] === data[`id_${tableName}`])) {
      const target = tableName === 'clients' ? 'cliente' : 'grupo';
      return this.swalService.error({ title: `Ya está asignada a este ${target}.`, text: '' });
    }
    this.form.get(tableName).setValue([
      ...this.form.get(tableName).value,
      {
        [`id_${tableName}`]: data[`id_${tableName}`],
        name: data.name
      }
    ]);
    this.form.markAsDirty();
  }

  private initBroadcast(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'assign.client': this.addItem('clients', event.data); break;
        case 'assign.group': this.addItem('groups', event.data); break;
      }
    });
  }

  private removeItem(table: string, itemId: number) {
    const actualItems = this.form.get(table).value.filter(item => item[`id_${table}`] !== itemId);
    this.form.get(table).setValue(actualItems);
    this.form.markAsDirty();
  }

  removeClient(clientId) {
    this.removeItem('clients', clientId);
  }

  removeGroup(groupId) {
    this.removeItem('groups', groupId);
  }

  save() {
    let service = this.pollsService.assign(this.data.pollId, this.form.value);
    const swalParams = {
      title: 'Asignación de encuesta',
      text: '¿Esta seguro de asignar esta encuesta a los siguientes clientes y/o grupos?'
    };

    if (this.form.valid) {
      this.swalService.warning(swalParams).then(result => {
        if (result.value) {
          this.subscription.add(service.subscribe((resp: any) => {
            if (resp.success) {
              this.swalService.success().then(() => {
                this.activeModal.dismiss();
              });
            } else {
              this.swalService.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
    this.subscription.unsubscribe();
  }

}

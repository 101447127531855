import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PollsService = /** @class */ (function () {
    function PollsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * getPolls
     * Obtiene la tabla con los datos de las encuestas almacenadas en la BD.
     * @param params parametros por los cuales se va a obtener la tabla, asi como el orden de los datos
     * @param filters filtros por los cuales se podra obtener datos especificos de la tabla en caso de ser necesario.
     * @returns los valores para dibujar la tabla
     */
    PollsService.prototype.getPolls = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/polls/getPolls", params);
    };
    /**
     * create
     * Envia una petición post para crear una encuesta con la opciones asociadas.
     * @param params name: nombre de la encuesta, area: area a la cual se va a evaluar, questions: array con las preguntas a evaluar
     * @returns 200 status OK | 500 error interno.
     */
    PollsService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/polls", params);
    };
    /**
     * delete
     * Envia una petición delete para borrar una encuesta con la opciones asociadas.
     * @param id id: de la encuesta a borrar
     * @returns 200 status OK | 404 status not found
     */
    PollsService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/polls/" + id);
    };
    /**
     * show
     * Envia una petición get para obtener  la informacion de una encuesta con la opciones asociadas.
     * @param id id: de la encuesta a consultar
     * @returns 200 status OK | 404 status not found
     */
    PollsService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/polls/" + id);
    };
    /**
     * update
     * Envia una petición put para actualizar una encuesta con la opciones asociadas.
     * @param params name: nombre de la encuesta, area: area a la cual se va a evaluar, questions: array con las preguntas a evaluar
     * @returns 200 status OK | 500 error interno.
     */
    PollsService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/polls/" + id, params);
    };
    /**
     * assign
     * Envia una petición put para actualizar una encuesta con la opciones asociadas.
     * @param id ID de la encuesta
     * @param params arrays de clientes y grupos
     * @returns 200 status OK | 500 error interno.
     */
    PollsService.prototype.assign = function (id, params) {
        return this.http.put(this.baseUrl + "/polls/" + id + "/assign", params);
    };
    /**
     * assignments
     * Envia una petición get para obtener las asignaciones de una encuesta.
     * @param id ID de la encuesta
     * @returns 200 status OK | 500 error interno.
     */
    PollsService.prototype.assignments = function (id) {
        return this.http.get(this.baseUrl + "/polls/" + id + "/assignments");
    };
    /**
     * results
     * Envia una petición get para obtener los resultados de una encuesta.
     * @param id ID de la encuesta
     * @returns 200 status OK | 500 error interno.
     */
    PollsService.prototype.getResults = function (id) {
        return this.http.get(this.baseUrl + "/polls/" + id + "/results");
    };
    PollsService.prototype.getSingleResults = function (id, questionId, page) {
        if (page === void 0) { page = '1'; }
        return this.http.get(this.baseUrl + "/polls/" + id + "/singleResults/" + questionId, { params: { page: page.toString() } });
    };
    PollsService.prototype.getEligibleClients = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/polls/getEligibleClients", params);
    };
    PollsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PollsService_Factory() { return new PollsService(i0.ɵɵinject(i1.HttpClient)); }, token: PollsService, providedIn: "root" });
    return PollsService;
}());
export { PollsService };
